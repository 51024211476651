define(['jquery', 'froogaloop'], function($, $f) {
  
  // Full width MyProtein video
  if ($('[data-component="editorialVideoScript"]').length) {
    var iframe = document.getElementById('video');
    var player = $f(iframe);

    $('.play').on('click', function() {
      $('.poster').fadeOut(250);
      $('.inner, .video-heading').hide();
      player.api('play');
      $('.video-wrapper').animate({
        height: '600px'
      }, 250, function() {
        $('iframe').animate({
          opacity: '1'
        }, 250, function() {});
      });
      $('.close').animate({
        opacity: 0
      }, 250, function() {
        $('.close').animate({
          opacity: 1
        }, 250);
      });
    });

    $('.close').on('click', function() {
      $('.close').animate({
        opacity: 0
      }, 250);
      $('iframe').animate({
        opacity: 0
      }, 0, function() {
        $('.video-wrapper').animate({
          height: '424px'
        }, 150, function() {
          player.api('unload');
        });
        $('.poster').fadeIn(250);
        $('.inner, .video-heading').fadeIn(250);
      });
    });
  }
});
